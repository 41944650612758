import { Tooltip } from '@progress/kendo-react-tooltip';
import { SecureComponent } from 'Components/Security';
import { Permission } from 'Models/Templates/Permission/Permission';
import { addEditRoute, AddEditSubRoute } from 'Navigation';
import React, { Fragment, PropsWithChildren, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Col, Row, Table } from 'reactstrap';
import {
  AsyncLoadingSection,
  PageCardSection,
  Render,
  SingleSectionPageCard,
  TaskPageHeaderLayout,
} from '../../Components/Display';
import {
  AddButton,
  SecondaryButton,
  SmartFileUploadDrawerInput,
  SmartInputBlock,
  TextDeleteButton,
} from '../../Components/Form';
import { useEnums, useFormStateValue } from '../../Context';
import { CoreEnum, DropdownItemModel, ReferralsRequest } from '../../Models';
import { InputType } from '../../Models/FormModels';
import { ColSize } from '../../Models/SharedModels';
import { ENDPOINTS } from '../../Models/Templates/Routes/Routes';
import {
  addEditTitle,
  BLANK_FIELD,
  buildEndpoint,
  buildQueryUrl,
  defaultDataTransform,
  useAddEditSuccessHandler,
  useApiWorker,
  useDataFetcher,
  useHasPermissions,
  useIdParam,
} from '../../Utilities';

export const AddEditReferralsRequest: React.FC = () => {
  const entityName = 'Referrals Request';
  const [id, isAdd] = useIdParam();

  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);

  const [data, loadingStatus] = useDataFetcher<ReferralsRequest>(
    buildQueryUrl(buildEndpoint(ENDPOINTS.referralsRequests.get), {
      id: id || '',
    }),
    new ReferralsRequest(),
    defaultDataTransform,
    ReferralsRequest
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.referralsRequests.base,
          handleSuccess: handleSuccess,
        }}
      >
        <ReferralsRequestsAddEditFormContent {...data} />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

const ReferralsRequestsAddEditFormContent: React.FC<ReferralsRequest> = ({
  ...data
}) => {
  const { state, urLevel, firstLevelReviewStatus, gender } = useEnums();
  const hasPermission = useHasPermissions();
  const [id, isAdd] = useIdParam();

  const [selectedClient, setClient] = useFormStateValue<
    DropdownItemModel | undefined
  >('client');

  const [documents, setDocuments] = useFormStateValue(
    'referralRequestDocuments'
  );

  const [benefitState, setBenefitState] = useFormStateValue<CoreEnum | undefined>('benefitState');

  let typeOfEvalReadEndpoint = buildEndpoint(
    ENDPOINTS.clients.endpointString('typeOfEvalDropdownItems'),
    selectedClient?.id
  );

  const sameClientAdjustersOnly = !hasPermission([
    Permission.ViewAllReferralSources,
  ]);

  // clear benefit state when there is a different client other than 'Corvel Tx'
  useEffect(() => {
    if (isAdd) {
      if (selectedClient?.name?.toLocaleLowerCase() === 'corvel tx') {
        setBenefitState({ value: 'TX', displayName: 'Texas' });
      } else {
        setBenefitState({ value: undefined, displayName: BLANK_FIELD });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  return (
    <Fragment>
      <SingleSectionPageCard cardTitle="Details" centerContent inputSection>
        <PageCardSection>
          <Row>
            <SmartInputBlock
              name="firstName"
              label="First Name"
              type={InputType.Text}
              required
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="lastName"
              label="Last Name"
              type={InputType.Text}
              required
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="gender"
              label="Gender"
              required
              type={InputType.EnumDropdown}
              data={gender}
              colProps={{ xs: ColSize.OneFourth }}
            />
            <SmartInputBlock
              name="referralNumber"
              label="Referral Number"
              type={InputType.Text}
              colProps={{ xs: ColSize.OneFourth }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="referralSource"
              label="Referral Source"
              type={InputType.AsyncDropDown}
              useQuery
              readEndpoint={ENDPOINTS.users.dropdownItems}
              otherparams={{
                isAdjuster: sameClientAdjustersOnly,
                isSameClient: sameClientAdjustersOnly,
              }}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="client"
              label="Client"
              type={InputType.AsyncDropDown}
              readEndpoint={ENDPOINTS.clients.dropdownItems}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="evalType"
              label="Type Of Evaluation"
              readEndpoint={typeOfEvalReadEndpoint}
              type={InputType.DropDown}
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="claimNumber"
              label="Claim Number"
              type={InputType.Text}
              required
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="providerRequestingTreatment"
              label="Provider Requesting Treatment"
              type={InputType.Text}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="providerPhone"
              label="Provider Phone"
              type={InputType.PhoneNumber}
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="reviewerSpeciality"
              label="Requested Reviewer Specialty"
              type={InputType.Text}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="benefitState"
              label="Benefit State"
              type={InputType.EnumDropdown}
              data={state}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="dueDate"
              label="Due Date"
              type={InputType.DatePicker}
              colProps={{ xs: ColSize.OneSixth }}
            />
            <SmartInputBlock
              name="dueDate"
              label="Due Time"
              type={InputType.TimePicker}
              colProps={{ xs: ColSize.OneSixth }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="referralDate"
              label="Referral Date"
              type={InputType.DatePicker}
              required
              value={Date.now}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="dateOfInjury"
              label="Date of Injury"
              type={InputType.DatePicker}
              required
              value={Date.now}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="dateOfBirth"
              label="Date of Birth"
              type={InputType.DatePicker}
              required
              value={Date.now}
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <SmartInputBlock
              name="specialInstructions"
              label="Special Instructions"
              type={InputType.TextArea}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SmartInputBlock
              name="physician"
              label="Physician"
              type={InputType.AsyncDropDown}
              readEndpoint={ENDPOINTS.physicians.endpointString(
                'DropdownItems'
              )}
              colProps={{ xs: ColSize.OneThird }}
            />
            <SecureComponent permissions={[Permission.AddPhysician]}>
              <Tooltip openDelay={100} position="bottom" anchorElement="target">
                <AddButton
                  url={addEditRoute(AddEditSubRoute.Physician)}
                  title="Make sure to save your Request First!"
                >
                  New Physician
                </AddButton>
              </Tooltip>
            </SecureComponent>
          </Row>

          <SecureComponent permissions={[Permission.AdjusterRestrictedFields]}>
            <Row>
              <SmartInputBlock
                name="firstLevelReviewStatus"
                label="First Level Review Status"
                data={firstLevelReviewStatus}
                type={InputType.EnumDropdown}
                colProps={{ xs: ColSize.OneThird }}
              />
              <SmartInputBlock
                name="sendTo"
                label="Send To"
                data={urLevel}
                type={InputType.EnumDropdown}
                colProps={{ xs: ColSize.OneThird }}
              />
              <SmartInputBlock
                name="externalAdjuster"
                label="External Adjuster"
                type={InputType.Text}
                colProps={{ xs: ColSize.OneThird }}
              />
            </Row>
          </SecureComponent>

          <Row>
            <SmartInputBlock
              name="contactAllowed"
              label="Contact Allowed"
              type={InputType.Checkbox}
              colProps={{ xs: ColSize.OneThird }}
            />
          </Row>
          <Row>
            <Render condition={isAdd}>
              <SmartFileUploadDrawerInput
                label="Medical Records"
                fileEndpoint={ENDPOINTS.referralrequestdocuments.base}
                required
                withComments={false}
                fileListName="referralRequestDocuments"
              />
            </Render>
            <Render condition={!isAdd}>
              <Tooltip
                openDelay={100}
                position="right"
                anchorElement="target"
                className=""
                key={2}
              >
                <span title="Upload your files within the Claimant Name Detail View">
                  <SecondaryButton disabled={true}>
                    Upload Files
                  </SecondaryButton>
                </span>
              </Tooltip>
            </Render>
          </Row>

          <Row className="mt-5">
            <Col>
              <ReferralRequestDocumentsMiniList
                documentList={documents}
                stateSetter={setDocuments}
              />
            </Col>
          </Row>
        </PageCardSection>
      </SingleSectionPageCard>
    </Fragment>
  );
};

class MiniListInput {
  documentList: any;
  stateSetter: any;
}

export const ReferralRequestDocumentsMiniList: React.FC<
  PropsWithChildren<MiniListInput>
> = ({ documentList, stateSetter, ...props }) => {
  const [id, isAdd] = useIdParam();
  const list =
    documentList && documentList.length > 0 ? (
      documentList.map((document: any, index: number, state: any) => {
        return (
          <tr key={index}>
            <td>{document.fileName} </td>
            <td>{(document.size / 1024).toFixed(2)} kb</td>
            <Render condition={isAdd}>
              <td>
                <ReferralRequestDeleteBox
                  item={document}
                  index={index}
                  stateSetter={stateSetter}
                  state={state}
                />
              </td>
            </Render>
          </tr>
        );
      })
    ) : (
      <tr>
        <td>No Documents Loaded</td>
      </tr>
    );
  return (
    <Table>
      <tbody>{list}</tbody>
    </Table>
  );
};

const ReferralRequestDeleteBox: React.FC<any> = ({
  item,
  index,
  state,
  stateSetter,
  ...props
}) => {
  const deletePermission = [Permission.DeleteReferralsRequestDocument];
  const API = useApiWorker();
  const deleteItem = async () => {
    const response = await API.post(
      buildEndpoint(
        ENDPOINTS.referralrequestdocuments.endpointString('DeleteTempBlob') +
        '?id=' +
        item.fileId
      )
    );
    if (response.data) {
      toast.success(`Medical Record Removed`);
      let newState = state.filter((doc: any) => doc.fileId != item.fileId);
      stateSetter(newState);
    } else {
      toast.error(`An error occurred removing the Medical Record`);
    }
  };

  return (
    <SecureComponent permissions={deletePermission}>
      <TextDeleteButton onClick={deleteItem} />
    </SecureComponent>
  );
};
