import { makeStyles } from '@mui/styles';
import {
  PageCard,
  PageCardRow,
  PageCardSection,
} from '../../Components/Display';
import { 
  useEntity 
} from '../../Context';
import React, { 
  Fragment 
} from 'react';
import { 
  Row, 
  Col 
} from 'reactstrap';
import { 
  buildRoute, 
  formatDateTimeFromDotNetFormat 
} from '../../Utilities';
import { ReferralRequestDocumentList } from '.';
import { 
  ButtonStyle, 
  IconButton 
} from 'Components/Form';
import Autorenew from '@mui/icons-material/Autorenew';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { ROUTES, EntitiesSubRoute } from 'Navigation';
import { useHistory } from 'react-router-dom';

export const ViewReferralRequest: React.FC = () => {
  const entity = useEntity();

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    icons: {
      justifyContent: 'flex-end',
    },
    overFlow: {
      overflowWrap: 'break-word',
    }
  });

  const classes = useStyles();

  const history = useHistory();
  const cancelAction = () => {
    history.push(
      buildRoute(ROUTES.ENTITIES, EntitiesSubRoute.ReferralsRequests)
    );
  };

  const convertAction = () => {
    history.push(buildRoute(ROUTES.REFERRALREQUESTPROPOSALS, entity.id));
  };

  return (
    <Fragment>
      <PageCard className={classes.root}>
        <PageCardRow>
          <PageCardSection title="Information">
            <Row className={classes.icons} >
              <Col xs="12" lg="6">
                <IconButton
                  className="float-right"
                  iconElement={Autorenew}
                  primary
                  buttonStyle={ButtonStyle.Bare}
                  onClick={convertAction}
                  title="Convert to Claimant"
                />
                <IconButton
                  className="float-right"
                  iconElement={ArrowBack}
                  primary
                  buttonStyle={ButtonStyle.Bare}
                  onClick={cancelAction}
                  title="cancel"
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Name:</span>
                <span className="field-value">{entity.name}</span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  Provider Phone:
                </span>
                <span className="field-value">{entity.providerPhone}</span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  Provider Requesting Treatment:
                </span>
                <span className="field-value">
                  {entity.providerRequestingTreatment}
                </span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">Referral Date:</span>
                <span className="field-value">
                  {entity.referralDate
                    ? formatDateTimeFromDotNetFormat(
                        entity.referralDate.toString(),
                        'MM/dd/yyyy'
                      )
                    : ''}
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  Referral Type:
                </span>
                <span className="field-value">{entity.referralType}</span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  Requested Reviewer Specialty:
                </span>
                <span className="field-value">{entity.reviewerSpeciality}</span>
              </Col>
            </Row>
            <Row className={classes.overFlow} >
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  Special Instructions:
                </span>
                <span className="field-value">{entity.specialInstructions}</span>
              </Col>
              <Col xs="12" lg="6">
                <span className="field-label data-display">
                  Type Of Evaluation:
                </span>
                <span className="field-value">{entity.typeOfEvaluation}</span>
              </Col>
            </Row>
          </PageCardSection>
        </PageCardRow>
        <PageCardRow>
          <PageCardSection>
            <ReferralRequestDocumentList />
          </PageCardSection>
        </PageCardRow>
      </PageCard>
    </Fragment>
  );
};
